<template>
  <!-- prettier-ignore -->
  <div
    class="person-name"
    v-text="name"
  />
</template>

<script setup>
import { computed } from 'vue'

import { DateTimeHelper } from '@/helpers/DateTime'

// INIT
const props = defineProps({
  noAge: {
    type: Boolean,
    default: false,
  },
  person: {
    type: Object,
    required: true,
  },
})

// COMPUTED
const isBirthday = computed(() => {
  return DateTimeHelper.isBirthday(props.person.personData.dateOfBirth)
})

const name = computed(() => {
  const lastName = props.person?.personData?.lastName || ''
  const fullName = `${props.person?.personData?.firstName || props.person.firstName} ${lastName}`
  const age = (!props.noAge && props.person.age) || ''

  let str = fullName
  if (age > 0) {
    str += ` (${age}${isBirthday.value ? '🎂' : ''})`
  }

  return str
})
</script>

<style scoped></style>
