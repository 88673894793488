import { getNextRoute } from '@/helpers/Route'

import basketStore from '@/store/basket'
import useBasket from '@/hooks/useBasket'

import { ROUTE_INDEX, ROUTE_NAME } from '@/config/constants'

export default [
  {
    beforeEnter: (_to, _from, next) => {
      const { isStaticBasket } = useBasket()

      if (isStaticBasket.value) {
        next({ name: getNextRoute(basketStore.basket) })
      } else {
        next()
      }
    },
    component: () => import('@/modules/Configurator/screens/Persons/ScreenPersons'),
    meta: {
      index: ROUTE_INDEX.PERSONS,
      secured: true,
    },
    name: ROUTE_NAME.PERSONS,
    path: '/persons/:personId?/:fieldName?/',
    props: route => ({
      personId: route.params.personId,
      fieldName: route.params.fieldName,
    }),
  },
  {
    component: () => import('@/modules/Configurator/screens/Persons/Immigrant'),
    meta: {
      index: ROUTE_NAME.PERSONS_IMMIGRANT,
      navigation: false,
      secured: true,
    },
    name: ROUTE_NAME.PERSONS_IMMIGRANT,
    path: '/persons/immigrant',
  },
]
