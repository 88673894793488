<template>
  <!-- prettier-ignore -->
  <div class="productPrice">
    <template v-if="props.product.prices.length > 0">
      <div class="productPrice__grid">
        <basic-price
          :price="selectedOption.price"
          :price-class="$props.priceClass"
          :additional="$props.additional"
          :decimals="$props.decimals"
          :prefix="$props.prefix"
          :prefix-class="$props.prefixClass"
          :show-zero-value="$props.showZeroValue"
          :suffix="$props.suffix"
          :suffix-class="$props.suffixClass"
        >
          <slot />
        </basic-price>
      </div>
    </template>

    <template v-else>
      {{ $t('product.unavailable') }}
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import BasicPrice from '@/components/Basic/Price'

// INIT
const props = defineProps({
  additional: {
    type: String,
    default: null,
  },

  decimals: {
    type: Number,
    default: 2,
  },

  prefix: {
    type: String,
    default: '',
  },

  prefixClass: {
    type: String,
    default: 'body-2',
  },

  priceClass: {
    type: String,
    default: '',
  },

  showZeroValue: {
    type: Boolean,
    default: false,
  },

  suffix: {
    type: String,
    default: null,
  },

  suffixClass: {
    type: String,
    default: 'body-2',
  },

  product: {
    type: Object,
    required: true,
  },
})

// COMPUTED
const selectedOption = computed(() => {
  return props.product.prices.find(p => p.selected) || {}
})
</script>

<style scoped>
.productPrice {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 4px;
  line-height: initial;

  &__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
  }

  &--invalid {
    color: var(--error);
  }
}
</style>
