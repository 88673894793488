import { createRouter, createMemoryHistory, createWebHashHistory } from 'vue-router'

import { logError } from '@/helpers/Logger'

import { events$ } from '@/services'
import sessionStore from '@/store/session'
import useApplication from '@/hooks/useApplication'
import useSession from '@/hooks/useSession'

import applicationRoutes from '@/modules/Configurator/routes/application'
import basketRoutes from '@/modules/Configurator/routes/basket'
import digitalSignatureRoutes from '@/modules/Configurator/routes/digitalSignature'
import nextStepsRoutes from '@/modules/Configurator/routes/nextSteps'
import notFoundRoutes from '@/modules/Configurator/routes/notFound'
import onboardingRoutes from '@/modules/Configurator/routes/onboarding'
import partnerRoutes from '@/modules/Configurator/routes/partner'
import personRoutes from '@/modules/Configurator/routes/person'
import productRoutes from '@/modules/Configurator/routes/product'
import quizRoutes from '@/modules/Configurator/routes/quiz'
import reentryRoutes from '@/modules/Configurator/routes/reentry'
import submitRoutes from '@/modules/Configurator/routes/submit'
import aideRoutes from '@/modules/Configurator/routes/aide'

import { EVENT_TRACKING } from '@/config/events'
import { DEFAULT_FOCUS_DELAY, ROUTE_NAME } from '@/config/constants'

// HOOKS
const { isWww } = useApplication()

/**
 * Load all route-components
 */
function getRoutes() {
  const rootRoute = {
    id: 'root',
    name: 'root',
    path: '/',
    redirect: '/persons',
  }

  return [
    ...applicationRoutes,
    ...basketRoutes,
    ...digitalSignatureRoutes,
    ...nextStepsRoutes,
    ...notFoundRoutes,
    ...onboardingRoutes,
    ...partnerRoutes,
    ...personRoutes,
    ...productRoutes,
    ...quizRoutes,
    ...reentryRoutes,
    ...submitRoutes,
    ...aideRoutes,
    rootRoute,
  ]
}

const Router = createRouter({
  history: isWww.value ? createMemoryHistory() : createWebHashHistory(),
  routes: getRoutes(),
  scrollBehavior(to) {
    // can be aborted via query-option on caller
    if (to.query.scrollTop === 'false') {
      return Promise.resolve()
    }

    // can be aborted via meta-option on route
    if (to.meta?.scrollTop === false) {
      return Promise.resolve()
    }

    if (window.pageYOffset > 0) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({
            top: 0,
            behavior: 'smooth',
          })
        }, DEFAULT_FOCUS_DELAY)
      })
    }
  },
})

Router.beforeEach(async (to, from, next) => {
  const { autostart } = useSession()

  const disableNavigation = !!(
    (window.OVP_APP_DISABLE_NAVIGATION || from.meta.disableNavigation) &&
    !to.query?.forcedRouting
  )

  if (disableNavigation) {
    next(false)
  } else {
    const isPrivate = to.meta?.secured ?? true
    let __basket
    if (isPrivate && !sessionStore.isActive.value) {
      try {
        __basket = await autostart({}, false)
      } catch (_e) {
        // issue with resuming/generating session => restart with fresh session
        __basket = await autostart({}, true)
      }

      // when the basket has already been submitted, everything should route to nextSteps
      if (to.name !== ROUTE_NAME.NEXT_STEPS && __basket.submitted) {
        next({
          name: ROUTE_NAME.NEXT_STEPS,
          replace: true,
          query: {
            forcedRouting: true,
          },
        })
        return
      }
    }

    next()
  }
})

Router.afterEach((to, from, failure) => {
  try {
    // if the routing gets aborted programmatically, skip the event/tracking
    if (!failure) {
      // we expect a sessionStorage item to be set, otherwise nothing will trigger
      events$.emit(EVENT_TRACKING.ROUTE_CHANGE, { opts: { strict: true }, to, from })
    }
  } catch (error) {
    logError(error)
  }
  if (window.ineum) {
    window.ineum('page', to.name)
  }
})

export default Router
